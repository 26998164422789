<template>
  <div>
    <div class="modal" id="update-record-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="update">
            <div class="modal-body">
              <div class="form-group">
                <label for="">Tracking No</label>
                <h5>
                  DTS-R13-{{ form.id }}
                  <span v-if="form.is_incoming">
                    <i class="bi bi-arrow-down"></i>
                  </span>
                  <span v-else>
                    <i class="bi bi-arrow-up"></i>
                  </span>
                </h5>
              </div>
              <div class="form-group">
                <label for="">Origin</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.origin"
                  required
                />
              </div>
              <div class="form-group">
                <label for="">Document Type</label>
                <select
                  class="form-control"
                  v-model="form.document_type"
                  required
                >
                  <option value="" selected disabled hidden>Choose</option>
                  <option value="Acknowledgement">Acknowledgement</option>
                  <option value="Complaint">Complaint</option>
                  <option value="Compliance">Compliance</option>
                  <option value="Data Request">Data Request</option>
                  <option value="Deficiency">Deficiency</option>
                  <option value="Information">Information</option>
                  <option value="Meeting">Meeting</option>
                  <option value="Memorandum">Memorandum</option>
                  <option value="Orientation">Orientation</option>
                  <option value="Program Application">Program App.</option>
                  <option value="Letter">Letter</option>
                  <option value="Indorsement">Indorsement</option>
                  <option value="Invitation">Invitation</option>
                  <option value="ISO-QMS">ISO-QMS</option>
                  <option value="Issuance">Issuance</option>
                  <option value="Receipt">Receipt</option>
                  <option value="Report">Report</option>
                  <option value="Request">Request</option>
                  <option value="Scholarship">Scholarship</option>
                  <option value="UNIFAST">UNIFAST</option>
                  <option value="Special Order">Special Order</option>
                  <option value="Training">Training</option>
                  <option value="Voucher">Voucher</option>
                </select>
              </div>

              <div class="form-group">
                <label for="">Title</label>
                <textarea
                  rows="2"
                  class="form-control"
                  v-model="form.title"
                  required
                ></textarea>
              </div>

              <div class="form-group">
                <label for="">Remarks (Optional)</label>
                <textarea
                  rows="2"
                  type="text"
                  class="form-control"
                  v-model="form.remarks"
                />
              </div>

              <div class="form-group">
                <label for="">Replace file</label>
                <input
                  type="file"
                  id="file-input"
                  class="form-control-file"
                  accept=".png, .jpeg, application/pdf, .doc, .docx"
                  @change="getFile"
                />
                <small class="form-text text-muted"
                  >File types: docx, jpeg/png, pdf</small
                >
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
              <button
                id="update-record-btn"
                type="submit"
                class="btn btn-primary"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdateRecordModal',
  data() {
    return {
      file: '',
      form: new Form({
        id: '',
        owner_id: '',
        origin: '',
        document_type: '',
        title: '',
        remarks: '',
      }),
    };
  },
  methods: {
    open(doc) {
      document.getElementById('file-input').value = null;
      this.file = '';
      $('#update-record-modal').modal('show');
      this.form.fill(doc);
    },

    update() {
      let b = document.getElementById('update-record-btn');
      b.disabled = true;
      b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> Updating...`;

      let fd = new FormData();

      for (let key in this.form) {
        fd.append(key, this.form[key]);
      }

      if (this.file) fd.append('file', this.file);

      axios
        .post('api/documents/update/' + this.form.id, fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          toastr.success(res.data.msg, 'Information');
          b.innerHTML = 'Update';
          b.disabled = false;
          $('#update-record-modal').modal('hide');
          this.$emit('hasUpdates', null);
        })
        .catch((err) => {
          b.innerHTML = 'Update';
          b.disabled = false;
          toastr.error(err.response.data.message, 'Error');
        });
    },
    getFile(event) {
      this.file = event.target.files[0];
    },
  },
};
</script>
