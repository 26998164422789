<template>
  <div class="">
    <div class="card rounded shadow-sm">
      <div class="card-body">
        <div class="row">
          <div class="col-auto">
            <div class="form-inline">
              <div class="form-group">
                <label for="">No. of rows</label>
                <select
                  name=""
                  id=""
                  class="form-control ml-2"
                  v-model="params.per_page"
                  @change="
                    getMyDocuments(DEFAULT_URL, {
                      tableLoader: false,
                      searchLoader: false,
                      actionLoader: false,
                    })
                  "
                >
                  <option value="10">10</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-4">
            <form
              @submit.prevent="
                getMyDocuments(DEFAULT_URL, {
                  tableLoader: false,
                  actionLoader: false,
                  searchLoader: true,
                })
              "
            >
              <div class="input-group">
                <select
                  name=""
                  class="form-control col-4"
                  v-model="params.column"
                  id=""
                >
                  <option value="dts_documents.title">Document Title</option>
                  <option value="dts_documents.origin">Origin</option>
                </select>

                <input
                  v-model="params.input"
                  type="search"
                  class="form-control "
                  placeholder="Enter keyword
              "
                />
                <div class="input-group-append">
                  <button type="submit" class="btn btn-outline-secondary">
                    <span v-if="!searchLoader">
                      <i class="bi bi-search text-dark"></i
                    ></span>
                    <span
                      v-else
                      class="spinner-border spinner-border-sm"
                    ></span>
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="col">
            <div class="dropdown">
              <button
                class="btn btn-outline-secondary text-dark dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :disabled="!selected.length"
              >
                <i class="bi bi-list-ul mr-1"></i> Actions
                <span v-show="selected.length">({{ selected.length }})</span>
                <div
                  v-if="actionLoader"
                  class="spinner-border spinner-border-sm mx-1"
                  role="status"
                >
                  <span class="invisible">Loading...</span>
                </div>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#" @click.prevent="forward">
                  <i class="bi bi-arrow-return-right mr-2"></i>Forward</a
                >

                <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="addActionTaken"
                >
                  <i class="bi bi-plus-lg mr-2"></i>Add action/s taken</a
                >
                <a class="dropdown-item" href="#" @click.prevent="markAsClosed">
                  <i class="bi bi-archive mr-2"></i>Mark as closed</a
                >
              </div>
            </div>
          </div>

          <div class="col-auto mr-auto">
            <div class="d-flex justify-content-right">
              <div class="my-auto mr-3">
                <span
                  >{{
                    `${pagination.from || 0}-${pagination.to ||
                      0} of ${pagination.total || 0}`
                  }}
                </span>
              </div>
              <nav>
                <ul class="pagination mb-0">
                  <li
                    class="page-item"
                    :class="{ disabled: !pagination.prev_page_url }"
                  >
                    <a
                      class="page-link"
                      href="#"
                      @click.prevent="
                        getMyDocuments(pagination.prev_page_url, {
                          tableLoader: false,
                          searchLoader: false,
                          actionLoader: false,
                        })
                      "
                    >
                      <span aria-hidden="true"
                        ><i class="bi bi-chevron-left"></i
                      ></span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>

                  <li
                    class="page-item"
                    :class="{ disabled: !pagination.next_page_url }"
                  >
                    <a
                      class="page-link"
                      href="#"
                      @click.prevent="
                        getMyDocuments(pagination.next_page_url, {
                          tableLoader: false,
                          searchLoader: false,
                          actionLoader: false,
                        })
                      "
                    >
                      <span aria-hidden="true"
                        ><i class="bi bi-chevron-right"></i
                      ></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div
          v-if="overdues.length"
          class="alert alert-danger alert-dismissible fade show mt-3 mb-0"
        >
          <strong
            ><i class="bi bi-exclamation-triangle-fill mr-1"></i> You have ({{
              overdues.length
            }}) overdue document/s.</strong
          >
          Please take necessary action/s immediately.
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="table-responsive">
          <table
            id="my-documents-table"
            class="table table-hover table-sm mt-4"
          >
            <thead>
              <th width="">
                <input
                  type="checkbox"
                  style="width: 15px; height: 15px;"
                  v-model="checked"
                  @change="checked ? selectAll() : unselectAll()"
                  :disabled="
                    documents.every((doc) => {
                      return doc.status == 'Closed';
                    })
                  "
                />
              </th>
              <th width="10%">Tracking No</th>
              <th width="35%">Document Title</th>
              <th width="">Origin</th>
              <th width="">Type</th>
              <th>Deadline</th>
              <th>Date Received</th>
              <th>Status</th>
              <th>Action</th>
            </thead>
            <tbody>
              <tr class="text-center" v-if="!documents.length && !tableLoader">
                <td colspan="11">
                  No results found
                </td>
              </tr>
              <tr class="text-center" v-if="tableLoader">
                <td colspan="11">
                  <div class="spinner-border spinner-border-sm mr-1"></div>
                  Loading
                </td>
              </tr>
              <tr
                :class="{
                  'font-weight-bold': d.status !== 'Closed',
                  'table-secondary': d.status == 'Closed',
                  'table-primary': selected.some((obj) => obj.id == d.id),
                }"
                v-for="d in checkedDocuments"
                :key="d.id"
                @click="openDocument(d)"
              >
                <td>
                  <input
                    v-if="d.status !== 'Closed'"
                    type="checkbox"
                    style="width: 15px; height: 15px;"
                    @click.stop
                    :value="d"
                    v-model="selected"
                  />

                  <i v-else class="bi bi-lock-fill mr-1"></i>
                </td>
                <td>
                  DTS-R13-{{ d.id }}
                  <span v-if="d.is_incoming">
                    <i class="bi bi-arrow-down"></i>
                  </span>
                  <span v-else>
                    <i class="bi bi-arrow-up"></i>
                  </span>
                </td>
                <td>
                  <i :class="[getFlagColor(d.priority_level)]"></i>{{ d.title }}
                  <span
                    v-if="d.is_overdue"
                    class="badge badge-pill badge-danger ml-1"
                    >Overdue</span
                  >
                </td>

                <td>{{ d.origin }}</td>
                <td>{{ d.document_type }}</td>

                <td>
                  <div v-if="d.deadline !== null">
                    {{ d.deadline | dateTime }}
                    <small
                      v-if="d.status != 'Closed'"
                      class="form-text text-muted"
                      >{{ d.deadline | dueIn }} ({{
                        d.transaction_type
                      }})</small
                    >
                  </div>
                  <span v-else>N/A</span>
                </td>
                <td>{{ d.received_at | dateTime }}</td>
                <td>
                  <span
                    v-if="d.status == 'Pending'"
                    class="badge badge-pill badge-danger"
                    >{{ d.status }}</span
                  >
                  <span v-else class="badge badge-pill badge-secondary">{{
                    d.status
                  }}</span>
                </td>
                <td>
                  <div class="btn-group">
                    <button
                      class="btn btn-sm btn-outline-secondary"
                      :disabled="
                        d.status == 'Closed' || d.encoded_by != d.owner_id
                      "
                      @click.stop="edit(d)"
                    >
                      <i class="bi bi-pencil text-dark"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <ForwardModal
      ref="ForwardModal"
      :selected="selected"
      v-on:hasUpdates="
        getMyDocuments(DEFAULT_URL, {
          tableLoader: false,
          searchLoader: false,
          actionLoader: false,
        })
      "
    >
    </ForwardModal>
    <DocumentDetailsModal ref="DocumentDetailsModal" :canAddActionTaken="true">
    </DocumentDetailsModal>
    <ActionsTakenModal
      ref="ActionsTakenModal"
      :selected="selected"
    ></ActionsTakenModal>

    <UpdateRecordModal
      ref="UpdateRecordModal"
      v-on:hasUpdates="
        getMyDocuments(DEFAULT_URL, {
          tableLoader: false,
          searchLoader: false,
          actionLoader: false,
        })
      "
    ></UpdateRecordModal>
  </div>
</template>

<script>
import ForwardModal from './ForwardModal.vue';
import DocumentDetailsModal from './DocumentDetailsModal.vue';
import ActionsTakenModal from './ActionsTakenModal.vue';
import UpdateRecordModal from './UpdateRecordModal.vue';
import { getFlagColor } from '../../../js/helpers';

export default {
  name: 'MyDocuments',
  components: {
    ForwardModal,
    DocumentDetailsModal,
    ActionsTakenModal,
    UpdateRecordModal,
  },
  data() {
    return {
      documents: [],
      selected: [],
      overdues: [],
      tableLoader: false,
      pagination: {},
      params: {
        column: 'dts_documents.title',
        input: '',
        per_page: 10,
        my_documents_only: true,
      },
      checked: false,
      actionLoader: false,
      searchLoader: false,
      DEFAULT_URL: 'api/documents/my-documents',
    };
  },
  methods: {
    openDocument(document) {
      this.$refs.DocumentDetailsModal.show(document);
    },
    edit(document) {
      this.$refs.UpdateRecordModal.open(document);
    },
    addActionTaken() {
      if (!this.selected.length)
        return toastr.error('No document(s) selected.', 'Error');
      this.$refs.ActionsTakenModal.show();
    },
    getMyDocuments(page_url, config) {
      this.selected = [];
      this.checked = false;
      page_url = page_url || this.DEFAULT_URL;

      this.tableLoader = config.tableLoader;
      this.searchLoader = config.searchLoader;
      this.actionLoader = config.actionLoader;

      axios
        .get(page_url, {
          params: this.params,
        })
        .then((res) => {
          this.makePagination(res.data);
          this.documents = res.data.data;
          this.tableLoader = this.actionLoader = this.searchLoader = false;
        })
        .catch((err) => {
          this.tableLoader = this.actionLoader = this.searchLoader = false;
        });
    },
    forward() {
      if (!this.selected.length)
        return toastr.error('No document(s) selected.', 'Error');
      this.$refs.ForwardModal.openModal();
    },
    removeItem(selected) {
      selected.forEach((obj) => {
        let index = this.documents.findIndex((doc) => doc.id == obj.id);
        this.documents.splice(index, 1);
      });
      this.selected = [];
    },
    markAsClosed() {
      if (!this.selected.length)
        return toastr.error('No document(s) selected.', 'Error');

      if (
        !confirm(
          'WARNING: You can no longer update this record once CLOSED. This record will be locked and will be limited to viewing only. \n\nAre you sure you want to proceed? This action cannot be undone.'
        )
      ) {
        return;
      }

      this.actionLoader = true;

      axios
        .put('api/documents/mark-as-closed', this.selected)
        .then((res) => {
          this.actionLoader = false;
          this.selected = [];
          toastr.success(res.data.msg, 'Information');
          this.getMyDocuments(this.DEFAULT_URL, {
            tableLoader: false,
            searchLoader: false,
            actionLoader: false,
          });
          this.checked = false;
        })
        .catch((err) => {
          this.actionLoader = false;
          toastr.err(err.response.data.message, 'Error');
        });
    },
    displaySearchResults(results) {
      this.documents = results;
    },
    getFlagColor,
    makePagination(data) {
      let pagination = {
        current_page: data.current_page,
        last_page: data.last_page,
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url,
        from: data.from,
        to: data.to,
        total: data.total,
      };

      this.pagination = pagination;
    },
    selectAll() {
      this.selected = this.documents.filter((doc) => doc.status === 'Pending');
    },

    unselectAll() {
      this.selected = [];
    },
  },
  computed: {
    checkedDocuments() {
      let now = moment();
      this.overdues = [];

      return this.documents.map((doc) => {
        if (doc.deadline == null || doc.status == 'Closed') {
          doc.is_overdue = false;
          return doc;
        }

        let diff = now.diff(doc.deadline, 'days');
        if (diff > 0) {
          doc.is_overdue = true;
          this.overdues.push(doc.deadline);
        } else doc.is_overdue = false;
        return doc;
      });
    },
  },
  mounted() {
    this.getMyDocuments(this.DEFAULT_URL, {
      tableLoader: true,
      searchLoader: false,
      actionLoader: false,
    });
  },
};
</script>

<style scoped>
#my-documents-table tr {
  cursor: pointer !important;
}
</style>
