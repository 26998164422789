<template>
  <div id="tracking-slip">
    <div
      style="position:fixed; top: 90px; left:270px;font-size: 20px; color: black !important;"
    >
      <header>
        <img
          src="@/assets/logo/header.png"
          width="530"
          height="140"
          alt="CHED Header Logo"
        />
      </header>

      <div
        style="position:fixed; top: 200px; left:400px;font-size: 20px; color: black !important;"
      >
        <h4 class="mt-5 text-center font-weight-bold">
          ACKNOWLEDGEMENT RECEIPT
        </h4>
      </div>

      <div style="position:fixed; top: 90px; left:780px;">
        <img
          src="@/assets/images/bagong-pilipinas-logo.png"
          width="140"
          height="132"
          alt="Bagong Pilipinas Logo"
        />
      </div>

      <div style="position:fixed; top: 320px; left:90px; font-size: 20px; ">
        <div class="border border-dark px-2 py-2" style="width: 265px;">
          <p class="mb-1">Tracking No.</p>
          <h2 class="font-weight-bold">DTS-R13-{{ document.id }}</h2>
        </div>

        <small class="form-text"
          >In following up, please refer to the tracking no.</small
        >
      </div>

      <div style="position:fixed; top: 500px; left:90px; font-size: 20px;">
        <p class="text-justify">
          The
          <strong>Commission on Higher Education - Caraga Region</strong>
          hereby formally acknowledges the receipt of your submission, which has
          been duly uploaded to the CHED Caraga Document Tracking System and
          will be forwarded to the personnel incharge for appropriate action.
        </p>
      </div>

      <div style="position:fixed; top: 630px; left: 90px; right:0px; ">
        <table
          id="acknowledgement-receipt-table"
          class="table table-bordered"
          style="font-size: 20px; color: black"
        >
          <tbody>
            <tr class="">
              <td
                style="border: 1px solid; border-color: black !important;"
                width="25%"
              >
                Origin / Source
              </td>
              <th style="border: 1px solid; border-color: black !important;">
                {{ document.origin }}
              </th>
            </tr>
            <tr>
              <td style="border: 1px solid; border-color: black !important;">
                Document Type
              </td>
              <th style="border: 1px solid; border-color: black !important;">
                {{ document.document_type }}
              </th>
            </tr>
            <tr>
              <td style="border: 1px solid; border-color: black !important;">
                Document Title
              </td>
              <th style="border: 1px solid; border-color: black !important;">
                {{ document.title }}
              </th>
            </tr>

            <tr>
              <td style="border: 1px solid; border-color: black !important;">
                Transaction Type
              </td>
              <th style="border: 1px solid; border-color: black !important;">
                {{ document.transaction_type }}
              </th>
            </tr>

            <tr>
              <td style="border: 1px solid; border-color: black !important;">
                Date Received
              </td>
              <th style="border: 1px solid; border-color: black !important;">
                {{ document.created_at | dateTime }}
              </th>
            </tr>

            <tr>
              <td style="border: 1px solid; border-color: black !important;">
                Remarks
              </td>
              <th style="border: 1px solid; border-color: black !important;">
                {{ document.remarks }}
              </th>
            </tr>
          </tbody>
        </table>
      </div>

      <div style="position:fixed; top: 1050px; left:90px; font-size: 20px;">
        <p class="text-justify">
          In compliance with Republic Act 11032, also known as the Ease of Doing
          Business Law, it is recommended that you utilize the provided tracking
          number for follow-up inquiries to ensure clarity and accurate document
          tracking.
        </p>

        <p>
          This receipt is automatically generated and does not necessitate a
          signature.
        </p>
      </div>

      <div style="position:fixed; top: 1280px; left:50px;">
        <img
          src="@/assets/images/rdc-i2fame-logo.png"
          width="115"
          height="110"
          alt="RDC i2Fame Logo"
        />
      </div>

      <div style="position:fixed; top: 1320px; left:170px;">
        <p class="mb-0">CHED-R13-DTS</p>
        <p>Printed on {{ Date() | dateTime }}</p>
      </div>

      <footer
        style="position:fixed; top: 1410px; left:90px; right: 0px; font-size: 20px;"
      >
        <hr style="border: 1px solid; border-color: black;" />
        <div class="text-center">
          <p class="mb-0">
            Higher Education Development Center Building, Brgy. Ampayon, Butuan
            City, Agusan Del Norte
          </p>
          <p>
            <i class="bi bi-globe"></i> chedcaraga.ph
            <i class="bi bi-envelope ml-3"></i> chedcaraga@ched.gov.ph
            <i class="bi bi-telephone ml-3"></i> (085) 817 3101
          </p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrackingSlip',
  data() {
    return {
      document: new Form({
        id: '',
        origin: '',
        document_type: '',
        transaction_type: '',
        title: '',
        status: '',
        deadline: '',
        description: '',
        remarks: '',
        path: '',
        created_at: '',
        encoded_by: '',
      }),
    };
  },
  methods: {
    print(document) {
      this.document.fill(document);
      setTimeout(() => {
        this.$htmlToPaper('tracking-slip');
      }, 1000);
    },
  },
};
</script>
