<template>
  <div>
    <div class="modal" id="document-details-modal">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row px-1 py-1">
              <div class="col-lg-4 col-xl-4 col-sm col-md col-xs">
                <div class="px-2">
                  <div class="">
                    <div class="d-flex justify-content-between">
                      <p class="font-weight-bold">File Details</p>
                      <a href="#" @click.prevent="printTrackingSlip">
                        <i class="bi bi-printer-fill mr-2"></i>Acknowledgement
                        Receipt</a
                      >
                    </div>

                    <table class="table table-borderless table-sm mb-0">
                      <tbody>
                        <tr>
                          <td width="20%">Tracking No</td>
                          <th>DTS-R13-{{ document.id }}</th>
                        </tr>
                        <tr>
                          <td>Title</td>
                          <th>{{ document.title }}</th>
                        </tr>
                        <tr>
                          <td>Origin</td>
                          <th>{{ document.origin }}</th>
                        </tr>
                        <tr>
                          <td>Type</td>
                          <th>{{ document.document_type }}</th>
                        </tr>
                        <tr>
                          <td>Transaction</td>
                          <th>{{ document.transaction_type }}</th>
                        </tr>

                        <tr>
                          <td>Deadline</td>
                          <th class="">
                            <div v-if="document.deadline !== null">
                              {{ document.deadline | dateTime }}

                              ({{ document.deadline | dueIn }})
                            </div>
                            <span v-else>N/A</span>
                          </th>
                        </tr>

                        <tr>
                          <td>Status</td>
                          <th>
                            <span
                              v-if="document.status == 'Pending'"
                              class="badge badge-pill badge-danger"
                              >{{ document.status }}</span
                            >
                            <span
                              v-else
                              class="badge badge-pill badge-secondary"
                              >Closed</span
                            >
                          </th>
                        </tr>

                        <tr>
                          <td>Remarks</td>
                          <th>{{ document.remarks }}</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr />
                </div>

                <div class="overflow-auto px-2" style="height: 550px;">
                  <div class="">
                    <div class="">
                      <div class="card-title">
                        <strong>Actions Taken</strong>
                      </div>
                      <div class="text-center" v-if="spinner">
                        <br />
                        <br />
                        <div
                          class="spinner-border text-secondary"
                          role="status"
                        ></div>
                      </div>

                      <ul class="timeline">
                        <li v-for="log in logs" :key="log.id">
                          <p class="mb-0 font-weight-bold">
                            {{ log.action }}
                          </p>

                          <a
                            href="#"
                            @click.prevent="openUrl(log.file_path)"
                            v-if="log.file_path"
                            ><i class="bi bi-file-earmark-pdf-fill mr-1"></i>
                            View file</a
                          >
                          <span
                            class=" form-text mt-0"
                            style="font-size: 12px;"
                          >
                            By: {{ `${log.first_name} ${log.last_name}` }} -
                            {{ log.created_at | completeDateTime }}
                          </span>
                        </li>
                      </ul>

                      <p
                        class="text-muted"
                        v-show="document.status == 'Closed' && duration"
                      >
                        Total duration of transaction:
                        <strong>{{ duration }} </strong>
                        <small class="form-text text-muted font-italic"
                          >*in business days</small
                        >
                      </p>

                      <div
                        class="form-group container"
                        v-if="canAddActionTaken && document.status != 'Closed'"
                      >
                        <form @submit.prevent="addActionTaken">
                          <textarea
                            name=""
                            id=""
                            class="form-control mb-3"
                            rows="2"
                            placeholder="Add action(s) taken here"
                            v-model="action"
                            required
                          ></textarea>
                          <button
                            id="insert-action-btn"
                            class="btn btn-primary mr-2"
                            type="submit"
                          >
                            Submit
                          </button>

                          <input
                            id="actions-taken-file-input"
                            type="file"
                            accept="application/pdf"
                            style="display:none"
                            @change="handleFileUpload"
                          />
                          <button
                            class="btn btn-outline-primary mr-2 "
                            @click.prevent="browseFiles"
                            :disabled="fileUploadLoader"
                          >
                            <i class="bi bi-cloud-arrow-up-fill mr-1"></i>
                            Upload file
                          </button>

                          <span class="small text-muted font-italic mr-3"
                            >(Max. size: 15MB)</span
                          >

                          <div
                            v-if="fileUploadLoader"
                            class="spinner-border text-primary spinner-border-sm"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-xl-8 col-sm col-md col-xs">
                <iframe
                  :src="src"
                  width="100%"
                  height="100%"
                  allow="autoplay"
                  class="rounded"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <TrackingSlip ref="TrackingSlip" v-show="false"></TrackingSlip>
  </div>
</template>

<script>
import TrackingSlip from './TrackingSlip.vue';

export default {
  name: 'DocumentDetailsModal',
  components: {
    TrackingSlip,
  },
  props: {
    canAddActionTaken: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      document: new Form({
        id: '',
        origin: '',
        document_type: '',
        transaction_type: '',
        title: '',
        status: '',
        deadline: '',
        description: '',
        remarks: '',
        path: '',
        created_at: '',
        encoded_by: '',
        date_received_for_encoding: '',
      }),
      src: '',
      logs: [],
      spinner: false,
      prefix: 'https://drive.google.com/file/d/',
      action: '',
      selected: [],
      duration: '',
      fileUploadLoader: false,
    };
  },
  methods: {
    browseFiles() {
      document.getElementById('actions-taken-file-input').click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.uploadFile(file);
    },
    uploadFile(file) {
      const fileInput = document.getElementById('actions-taken-file-input');

      this.fileUploadLoader = true;
      const fd = new FormData();
      fd.append('file', file);
      fd.append('id', this.document.id);

      axios
        .post('api/documents/actions/file', fd)
        .then((res) => {
          fileInput.value = '';
          this.fileUploadLoader = false;
          this.getLogs({ id: this.document.id, toggle: false });
        })
        .catch((err) => {
          fileInput.value = '';
          toastr.error(err.response.data.msg, 'Error');
          this.fileUploadLoader = false;
        });
    },
    show(document) {
      this.url(document.path);
      this.getLogs({ id: document.id, toggle: true });
      this.document.fill(document);
      this.selected = [];
      this.duration = '';
      $('#document-details-modal').modal('show');
    },

    url(path) {
      this.src = `https://drive.google.com/file/d/${path}/preview`;
    },

    openUrl(path) {
      const url = `https://drive.google.com/file/d/${path}/preview`;
      window.open(url, '_blank');
    },
    getLogs(data) {
      this.spinner = data.toggle;
      axios
        .get('api/documents/logs/' + data.id)
        .then((res) => {
          this.logs = res.data;
          this.spinner = false;
          this.getDuration();
        })
        .catch((err) => {
          this.spinner = false;
          toastr.error(err.response.data.message, 'Error');
        });
    },
    printTrackingSlip() {
      this.$refs.TrackingSlip.print(this.document);
    },
    addActionTaken() {
      let b = document.getElementById('insert-action-btn');
      b.disabled = true;
      b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> Submitting...`;
      this.selected.push(this.document);

      let params = {
        action: this.action,
        selected: this.selected,
      };

      axios
        .post('api/documents/action-taken/insert', params)
        .then((res) => {
          b.innerHTML = 'Submit';
          b.disabled = false;
          this.getLogs({
            id: this.document.id,
            toggle: false,
          });
          this.action = '';
          this.selected = [];
        })
        .catch((err) => {
          toastr.error(err.response.data.message, 'Error');
          b.innerHTML = 'Submit';
          b.disabled = false;
        });
    },
    getDuration() {
      let firstLog = this.logs[0].created_at;
      let lastLog = moment(this.logs[this.logs.length - 1].created_at);
      this.duration = moment(firstLog).businessDiff(lastLog);
    },
  },
  computed: {},
  mounted() {
    $('#document-details-modal').on('hidden.bs.modal', () => {
      this.src = '';
      this.document.reset();
      this.logs = '';
    });
  },
};
</script>

<style scoped>
ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #bcc0c5;
  display: inline-block;
  position: absolute;
  left: 7px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 0px;
}
ul.timeline > li:before {
  content: '';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #bcc0c5;
  left: 0px;

  width: 15px;
  height: 15px;
  z-index: 400;
}
</style>
